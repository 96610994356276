<div class="plor-features-area">
    <div class="container">
        <div class="plor-features-inner-box">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="plor-features-box wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                        <div class="features-image">
                            <a href="single-services.html"><img src="assets/img/more-home/features/features-4.png" alt="image"></a>
                        </div>
                        <h3>
                            <a href="single-services.html">Marketing Analysis</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="plor-features-box wow animate__animated animate__fadeInDown" data-wow-delay="200ms" data-wow-duration="1000ms">
                        <div class="features-image">
                            <a href="single-services.html"><img src="assets/img/more-home/features/features-5.png" alt="image"></a>
                        </div>
                        <h3>
                            <a href="single-services.html">Website Optimization</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="plor-features-box wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                        <div class="features-image">
                            <a href="single-services.html"><img src="assets/img/more-home/features/features-6.png" alt="image"></a>
                        </div>
                        <h3>
                            <a href="single-services.html">Email Marketing</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>