<div class="plor-team-area pt-100 pb-100">
    <div class="container">
        <div class="section-title with-gradient-color">
            <span class="sub-title">OUR TEAM</span>
            <h2>We Help to Acheive Your Business Goal</h2>
        </div>
        <div class="plor-team-slides owl-carousel owl-theme">
            <div class="plor-team-card text-center wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                <div class="team-image">
                    <img src="assets/img/more-home/team/team-5.png" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="team-content">
                    <h3>James Anderson</h3>
                    <span>CEO</span>
                </div>
            </div>
            <div class="plor-team-card text-center wow animate__animated animate__fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                <div class="team-image">
                    <img src="assets/img/more-home/team/team-6.png" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="team-content">
                    <h3>Alina Smith</h3>
                    <span>Marketing Lead</span>
                </div>
            </div>
            <div class="plor-team-card text-center wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                <div class="team-image">
                    <img src="assets/img/more-home/team/team-7.png" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="team-content">
                    <h3>Sarah Taylor</h3>
                    <span>Advisor</span>
                </div>
            </div>
            <div class="plor-team-card text-center wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                <div class="team-image">
                    <img src="assets/img/more-home/team/team-8.png" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="team-content">
                    <h3>David Warner</h3>
                    <span>Support</span>
                </div>
            </div>
            <div class="plor-team-card text-center wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                <div class="team-image">
                    <img src="assets/img/more-home/team/team-5.png" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="team-content">
                    <h3>Alina Smith</h3>
                    <span>CEO</span>
                </div>
            </div>
            <div class="plor-team-card text-center wow animate__animated animate__fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                <div class="team-image">
                    <img src="assets/img/more-home/team/team-6.png" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="team-content">
                    <h3>Sarah Taylor</h3>
                    <span>Marketing Lead</span>
                </div>
            </div>
            <div class="plor-team-card text-center wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                <div class="team-image">
                    <img src="assets/img/more-home/team/team-7.png" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="team-content">
                    <h3>Alina Smith</h3>
                    <span>Advisor</span>
                </div>
            </div>
            <div class="plor-team-card text-center wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                <div class="team-image">
                    <img src="assets/img/more-home/team/team-8.png" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="team-content">
                    <h3>David Warner</h3>
                    <span>Support</span>
                </div>
            </div>
        </div>
        <div class="plor-team-bottom-text">
            <a routerLink="/team-1">See All Our Team Member</a>
        </div>
    </div>
</div>