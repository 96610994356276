<section class="featured-services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-boxes-box">
                    <div class="icon">
                        <i class="flaticon-research"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                        </div>
                    </div>
                    <h3><a href="single-services.html">Marketing Analysis</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a href="single-services.html" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-boxes-box">
                    <div class="icon">
                        <i class="flaticon-speed"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                        </div>
                    </div>
                    <h3><a href="single-services.html">Website Optimization</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a href="single-services.html" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-boxes-box">
                    <div class="icon">
                        <i class="flaticon-email-marketing"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                        </div>
                    </div>
                    <h3><a href="single-services.html">Email Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a href="single-services.html" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>