<div class="ds-team-area pt-100 pb-70">
    <div class="container">
        <div class="ds-section-title">
            <span>Team</span>
            <h2>Meet our expert team</h2>
            <p>Integer vel elit congue accumsan ligula placerat mi uisque tempor sapien justo sed accumsan tortor ultrices nec.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="ds-team-card">
                    <div class="team-image">
                        <img src="assets/img/data-science/team/team1.jpg" alt="image">
                    </div>
                    <div class="team-content">
                        <h3>Mike marco</h3>
                        <span>President</span>
                    </div>
                    <div class="team-hover-content">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                        <h3>Mike marco</h3>
                        <span>President</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="ds-team-card">
                    <div class="team-image">
                        <img src="assets/img/data-science/team/team2.jpg" alt="image">
                    </div>
                    <div class="team-content">
                        <h3>Nick davis</h3>
                        <span>Head of HR</span>
                    </div>
                    <div class="team-hover-content">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                        <h3>Nick davis</h3>
                        <span>Head of HR</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="ds-team-card">
                    <div class="team-image">
                        <img src="assets/img/data-science/team/team3.jpg" alt="image">
                    </div>
                    <div class="team-content">
                        <h3>Ken william</h3>
                        <span>Art director</span>
                    </div>
                    <div class="team-hover-content">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                        <h3>Ken william</h3>
                        <span>Art director</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="ds-team-card">
                    <div class="team-image">
                        <img src="assets/img/data-science/team/team4.jpg" alt="image">
                    </div>
                    <div class="team-content">
                        <h3>Robert pease</h3>
                        <span>Business advisor</span>
                    </div>
                    <div class="team-hover-content">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                        <h3>Robert pease</h3>
                        <span>Business advisor</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>