<div class="plor-solutions-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="plor-solutions-image">
                    <img src="assets/img/more-home/solutions/solutions.png" alt="image">
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube">
                        <i class="flaticon-play-button"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="plor-solutions-content">
                    <span>DIGITAL MARKETING</span>
                    <h3>Creative Solutions Creative Results</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="solutions-inner-content">
                        <h4>Core Development</h4>
                        <p>Quis ipsum suspendisse ultrices gravida sed do eiusmod tempor.</p>
                    </div>
                    <div class="solutions-inner-content color-two">
                        <h4>Define Your Choices</h4>
                        <p>Quis ipsum suspendisse ultrices gravida sed do eiusmod tempor.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>