<app-homenine-main-banner></app-homenine-main-banner>

<app-features-style-two></app-features-style-two>

<app-homenine-about></app-homenine-about>

<app-services-style-two></app-services-style-two>

<app-what-we-do-style-three></app-what-we-do-style-three>

<app-case-studies></app-case-studies>

<app-team-style-four></app-team-style-four>

<app-video></app-video>

<app-pricing-style-three></app-pricing-style-three>

<app-get-started></app-get-started>

<app-testimonials-style-three></app-testimonials-style-three>

<app-homenine-blog></app-homenine-blog>