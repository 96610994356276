import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  async loadTranslation(lang?: string) {
    console.log(lang);
    if (!lang)
      lang = String(this.translate.getBrowserLang());

    let language;
    if(environment.loadLang=='local'){
      language = await this.http.get('https://'+environment.host+'/assets/language/'+lang).toPromise();
    }
    else{
      language = await this.http.get('https://api.spacetranslate.com/entegiweb/latest/' + lang).toPromise();
    }
    //
     //await this.http.get('https://api.spacetranslate.com/entegiweb/latest/' + lang).toPromise();
    
    //const language = await this.http.get('https://'+environment.host+'/assets/language/'+lang).toPromise();
    this.translate.use(lang);
    this.translate.setTranslation(lang, language!);
  }

  async UploadImage(file: FormData) {
    let img: any = {
      "user_id": sessionStorage.getItem("user_id"),
      "site": environment.host,
      "file": file
    }
  }

  async CheckData(section: string, data: any) {
    const model: object = {
      p_user_id: String(sessionStorage.getItem("user_id")),
      p_section: section,
      p_data: data
    };

    const result = await this.baseService.post('rpc/check_data', model);
    return result;
  }

}
