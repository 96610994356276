<section class="about-area pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/entegi_marketplace_integration.png" alt="image" class="main_left_image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="text">
                        <span class="sub-title">{{'text_about_us'| translate}}</span>
                        <h2>{{'text_home_about_us_title'|translate}}</h2>
                        <p>{{'text_home_about_us_detail'|translate}}</p>
                        <!-- <ul class="funfacts-list">
                            <li>
                                <div class="list">
                                    <i class="flaticon-menu-1"></i>
                                    <h3 class="odometer" data-count="20">00</h3>
                                    <p>{{'text_integration'|translate}}</p>
                                </div>
                            </li>
                            <li>
                                <div class="list">
                                    <i class="flaticon-web-settings"></i>
                                    <h3 class="odometer" data-count="50">00</h3>
                                    <p>{{'text_xml_integration'|translate}}</p>
                                </div>
                            </li>
                            <li>
                                <div class="list">
                                    <i class="flaticon-conversation"></i>
                                    <h3 class="odometer" data-count="8">00</h3>
                                    <p>{{'text_cargo_integration'|translate}}</p>
                                </div>
                            </li>
                        </ul> -->
                        <a routerLink="/about-us-1" class="default-btn">{{'text_detail'|translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>