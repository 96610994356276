<div class="digital-agency-banner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <span class="sub-title wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">Digital Marketing</span>
                    <h1 class="wow animate__animated animate__fadeInLeft" data-wow-delay="10ms" data-wow-duration="1000ms">Digital Agency with Excellence Service</h1>
                    <p class="wow animate__animated animate__fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">We have created a cloud based tool to do your all boring tasks related to data analysis and decesion making based on the nature of data!</p>
                    <div class="btn-box wow animate__animated animate__fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
                        <a routerLink="/about-us-1" class="default-btn">{{'text_more'|translate}}</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-image">
                    <img src="assets/img/banner-img4.png" class="wow animate__animated animate__fadeInUp" alt="image" data-wow-delay="00ms" data-wow-duration="1000ms">
                </div>
            </div>
        </div>
    </div>
</div>