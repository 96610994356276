<div class="it-book-appointment-area pb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="it-book-appointment-image">
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube">
                        <i class="flaticon-play-button"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <form class="it-book-appointmen-form">
                    <div class="content">
                        <h3>Book appointment</h3>
                        <span>It's out pleasure to have a chance to cooperate.</span>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Your name">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Your email">
                    </div>
                    <div class="form-group">
                        <div class="select-box">
                            <select>
                                <option>Software licensing</option>
                                <option>Corporate finance</option>
                                <option>Manage investment</option>
                                <option>Business planning</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn">Submit now</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>