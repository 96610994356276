<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Services</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="featured-services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-research"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">Marketing Analysis</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-speed"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">Website Optimization</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-email-marketing"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">Email Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-megaphone"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">Social Media Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-keywords"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">Keyward Research</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-content-management"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">Content Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-ppc"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">PPC Advertising</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-competitor"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">Competitor Research</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="flaticon-startup"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">Skyrocketing Growth</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>