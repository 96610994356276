<div class="plor-pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title with-gradient-color">
            <span class="sub-title">PRICING</span>
            <h2>Our Flexible Pricing Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="plor-pricing-card wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                    <div class="pricing-header">
                        <h3>Starter Plan</h3>
                    </div>
                    <div class="pricing-price">
                        $49.99 <span>Per Month</span>
                    </div>
                    <ul class="pricing-list">
                        <li><i class='bx bx-check'></i> 10GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 25 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>
                    <div class="pricing-btn">
                        <a routerLink="/contact" class="default-btn-with-radius">Book Now <i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="plor-pricing-card active wow animate__animated animate__fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms">
                    <div class="pricing-header">
                        <h3>Advance Plan</h3>
                    </div>
                    <div class="pricing-price">
                        $69.99 <span>Per Month</span>
                    </div>
                    <ul class="pricing-list">
                        <li><i class='bx bx-check'></i> 10GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 25 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>
                    <div class="pricing-btn">
                        <a routerLink="/contact" class="default-btn-with-radius">Book Now <i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="plor-pricing-card wow animate__animated animate__fadeInUp" data-wow-delay="600ms" data-wow-duration="1000ms">
                    <div class="pricing-header">
                        <h3>Premium Plan</h3>
                    </div>
                    <div class="pricing-price">
                        $99.99 <span>Per Month</span>
                    </div>
                    <ul class="pricing-list">
                        <li><i class='bx bx-check'></i> 10GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 25 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>
                    <div class="pricing-btn">
                        <a routerLink="/contact" class="default-btn-with-radius">Book Now <i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>