<div class="plor-case-studies-area-without-color ptb-100">
    <div class="container">
        <div class="section-title with-gradient-color">
            <span class="sub-title">CASE STUDIES</span>
            <h2>Check of Our Some Recent Works & Case Studies</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6">
                <div class="plor-case-studies-card with-style-two wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                    <a href="single-portfolio.html">
                        <img src="assets/img/more-home/case-studies/case-studies-1.jpg" alt="image">
                    </a>
                    <div class="content">
                        <h3>
                            <a href="single-portfolio.html">Data Scientist</a>
                        </h3>
                        <div class="icon">
                            <a href="single-portfolio.html"><i class="flaticon-next-button"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="plor-case-studies-card with-style-two wow animate__animated animate__fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                    <a href="single-portfolio.html">
                        <img src="assets/img/more-home/case-studies/case-studies-2.jpg" alt="image">
                    </a>
                    <div class="content">
                        <h3>
                            <a href="single-portfolio.html">Data Analysis</a>
                        </h3>
                        <div class="icon">
                            <a href="single-portfolio.html"><i class="flaticon-next-button"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="plor-case-studies-card with-style-two wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                    <a href="single-portfolio.html">
                        <img src="assets/img/more-home/case-studies/case-studies-3.jpg" alt="image">
                    </a>
                    <div class="content">
                        <h3>
                            <a href="single-portfolio.html">Detection Project</a>
                        </h3>
                        <div class="icon">
                            <a href="single-portfolio.html"><i class="flaticon-next-button"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="plor-case-studies-card with-style-two wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                    <a href="single-portfolio.html">
                        <img src="assets/img/more-home/case-studies/case-studies-4.jpg" alt="image">
                    </a>
                    <div class="content">
                        <h3>
                            <a href="single-portfolio.html">Benefits Research</a>
                        </h3>
                        <div class="icon">
                            <a href="single-portfolio.html"><i class="flaticon-next-button"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="plor-case-studies-bottom-text">
            <a href="portfolio-1.html">See All Case Studies</a>
        </div>
    </div>
</div>