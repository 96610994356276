<div class="ds-case-study-area pt-100 pb-70">
    <div class="container">
        <div class="ds-section-title">
            <span>Case study</span>
            <h2>Our recent works</h2>
            <p>Integer vel elit congue accumsan ligula placerat mi uisque tempor sapien justo sed accumsan tortor ultrices nec.</p>
        </div>

        <div class="ds-case-study-list-tabs">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="all-tab" data-bs-toggle="tab" href="#all" role="tab" aria-controls="all">All</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="data-collect-tab" data-bs-toggle="tab" href="#data-collect" role="tab" aria-controls="data-collect">Data collect</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="analysis-tab" data-bs-toggle="tab" href="#analysis" role="tab" aria-controls="analysis">Analysis</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="health-tab" data-bs-toggle="tab" href="#health" role="tab" aria-controls="health">Health</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="marketing-tab" data-bs-toggle="tab" href="#marketing" role="tab" aria-controls="marketing">Marketing</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="travel-tab" data-bs-toggle="tab" href="#travel" role="tab" aria-controls="travel">Travel</a>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="all" role="tabpanel">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case1.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case2.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case3.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case4.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case5.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case6.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="data-collect" role="tabpanel">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case1.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case2.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case3.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case4.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case5.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case6.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="analysis" role="tabpanel">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case1.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case2.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case3.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case4.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case5.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case6.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="health" role="tabpanel">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case1.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case2.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case3.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case4.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case5.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case6.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="marketing" role="tabpanel">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case1.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case2.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case3.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case4.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case5.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case6.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="travel" role="tabpanel">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case1.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case2.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case3.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case4.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card with-top">
                                <img src="assets/img/data-science/case-study/case5.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="ds-case-study-card">
                                <img src="assets/img/data-science/case-study/case6.jpg" alt="image">
                                <div class="content">
                                    <span>Analysis</span>
                                    <h3>
                                        <a routerLink="/single-portfolio">Awesome data analysis project</a>
                                    </h3>
                                </div>
                                <div class="icon">
                                    <a routerLink="/single-portfolio"><i class='bx bx-search'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>