<section class="services-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Services</span>
            <h2>Let’s Check Our Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-megaphone"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="single-services">Social Media Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-keywords"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="single-services">Keyward Research</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-content-management"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="single-services">Content Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-ppc"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="single-services">PPC Advertising</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-competitor"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="single-services">Competitor Research</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-startup"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="single-services">Skyrocketing Growth</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>