<div class="ds-process-area pb-70">
    <div class="container">
        <div class="ds-section-title">
            <span>Process</span>
            <h2>Our work process</h2>
            <p>Integer vel elit congue accumsan ligula placerat mi uisque tempor sapien justo sed accumsan tortor ultrices nec.</p>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-3 col-sm-6">
                <div class="ds-process-card">
                    <div class="number">01</div>
                    <h3>Frame the problem</h3>
                    <p>In malesuada sed enim vel rutrum finibus commodo mi ac lacinia ibulum eget interdum mi.</p>
                    <div class="icon">
                        <img src="assets/img/data-science/process/icon1.svg" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="ds-process-card style-two">
                    <h3>Frame the problem</h3>
                    <p>In malesuada sed enim vel rutrum finibus commodo mi ac lacinia ibulum eget interdum mi.</p>
                    <div class="number">02</div>
                    <div class="icon">
                        <img src="assets/img/data-science/process/icon2.svg" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="ds-process-card">
                    <div class="number">03</div>
                    <h3>Process the data</h3>
                    <p>In malesuada sed enim vel rutrum finibus commodo mi ac lacinia ibulum eget interdum mi.</p>
                    <div class="icon">
                        <img src="assets/img/data-science/process/icon3.svg" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="ds-process-card style-two">
                    <h3>Complete the target</h3>
                    <p>In malesuada sed enim vel rutrum finibus commodo mi ac lacinia ibulum eget interdum mi.</p>
                    <div class="number">04</div>
                    <div class="icon">
                        <img src="assets/img/data-science/process/icon4.svg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>