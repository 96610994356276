<section class="history-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our History</span>
            <h2>History Begins In 2010</h2>
        </div>

        <ol class="timeline history-timeline">
            <li class="timeline-block">
                <div class="timeline-date">
                    <span>2010</span>
                    February 20
                    <sup>th</sup>
                </div>

                <div class="timeline-icon">
                    <span class="dot-badge"></span>
                </div>

                <div class="timeline-content">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="content">
                                <h3>Founded</h3>
                                <p>Real innovations and a positive customer experience are the heart of successful communication. Lorem ipsum dolor sit amet, sectetur adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="image">
                                <img src="assets/img/history/history-img1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <li class="timeline-block">
                <div class="timeline-date">
                    <span>2013</span>
                    January 14
                    <sup>th</sup>
                </div>

                <div class="timeline-icon">
                    <span class="dot-badge"></span>
                </div>

                <div class="timeline-content">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="content">
                                <h3>Global Success</h3>
                                <p>Real innovations and a positive customer experience are the heart of successful communication. Lorem ipsum dolor sit amet, sectetur adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="image">
                                <img src="assets/img/history/history-img2.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <li class="timeline-block">
                <div class="timeline-date">
                    <span>2016</span>
                    March 25
                    <sup>th</sup>
                </div>

                <div class="timeline-icon">
                    <span class="dot-badge"></span>
                </div>

                <div class="timeline-content">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="content">
                                <h3>Founded Data Office</h3>
                                <p>Real innovations and a positive customer experience are the heart of successful communication. Lorem ipsum dolor sit amet, sectetur adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="image">
                                <img src="assets/img/history/history-img3.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <li class="timeline-block">
                <div class="timeline-date">
                    <span>2024</span>
                    December 10
                    <sup>th</sup>
                </div>

                <div class="timeline-icon">
                    <span class="dot-badge"></span>
                </div>

                <div class="timeline-content">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="content">
                                <h3>International Award</h3>
                                <p>Real innovations and a positive customer experience are the heart of successful communication. Lorem ipsum dolor sit amet, sectetur adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="image">
                                <img src="assets/img/history/history-img4.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ol>
    </div>
</section>