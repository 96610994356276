import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage-twelve',
  templateUrl: './homepage-twelve.component.html',
  styleUrls: ['./homepage-twelve.component.scss']
})
export class HomepageTwelveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
