import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-we-do-style-two',
  templateUrl: './what-we-do-style-two.component.html',
  styleUrls: ['./what-we-do-style-two.component.scss']
})
export class WhatWeDoStyleTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
