<section class="plor-subscribe-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="plor-subscribe-content wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                    <span class="sub-title">PLOR UPDATES</span>
                    <h2>Subscribe To Our Newsletter</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <form class="newsletter-form">
                        <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                        <button type="submit" class="default-btn-with-radius">Subscribe Now <i class="flaticon-next-button"></i></button>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="plor-subscribe-image wow animate__animated animate__fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms">
                    <img src="assets/img/more-home/subscribe/subscribe.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="plor-subscribe-vector-shape">
        <img src="assets/img/more-home/subscribe/vector-shape.png" alt="image">
    </div>
</section>