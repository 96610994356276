import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-we-do-style-three',
  templateUrl: './what-we-do-style-three.component.html',
  styleUrls: ['./what-we-do-style-three.component.scss']
})
export class WhatWeDoStyleThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
