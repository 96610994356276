<div class="it-team-area pt-100 pb-70">
    <div class="container">
        <div class="startup-it-agency-title style-two">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="div">
                        <span>Our team member</span>
                        <h2>Meet our professional expert team member</h2>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore dolore magna aliqua. quis adipisicing elit nostrud exercitation ullamco laboris.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="it-team-card">
                    <div class="image">
                        <img src="assets/img/startup-it-agency/team/team1.jpg" alt="team-image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Mike marco</h3>
                        <span>President</span>
                        <i class="flaticon-share-icon"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="it-team-card">
                    <div class="image">
                        <img src="assets/img/startup-it-agency/team/team2.jpg" alt="team-image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Nick davis</h3>
                        <span>Head of HR</span>
                        <i class="flaticon-share-icon"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="it-team-card">
                    <div class="image">
                        <img src="assets/img/startup-it-agency/team/team3.jpg" alt="team-image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Ken william</h3>
                        <span>Art director</span>
                        <i class="flaticon-share-icon"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="it-team-card">
                    <div class="image">
                        <img src="assets/img/startup-it-agency/team/team4.jpg" alt="team-image">

                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Robert pease</h3>
                        <span>Business advisor</span>
                        <i class="flaticon-share-icon"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>