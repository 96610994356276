<!-- This section for Home Demo - 11 -->
<div
    class="it-about-area pb-100"
    [ngClass]="{'d-none': router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="it-about-image">
                    <img src="assets/img/startup-it-agency/about/about.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="it-about-content">
                    <span>About Plor</span>
                    <h3>Any IT related work solution can be easily found from us</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit illo ducimus vero, vero corporis voluptates beatae pariatur quisquam ess voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="inner-card">
                                <div class="icon">
                                    <img src="assets/img/startup-it-agency/about/icon1.svg" alt="image">
                                </div>
                                <h4>Product development</h4>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="inner-card">
                                <div class="icon">
                                    <img src="assets/img/startup-it-agency/about/icon2.svg" alt="image">
                                </div>
                                <h4>Content management</h4>
                            </div>
                        </div>
                    </div>
                    <ul class="list">
                        <li><i class='bx bx-check-circle'></i> Donec scelerisque egestas purus eget fringilla</li>
                        <li><i class='bx bx-check-circle'></i> Ensu mauris imperdiet non ligula et lobortis re security</li>
                        <li><i class='bx bx-check-circle'></i> Lorem ipsum dolor sit amet consectetur nod</li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="/about-us-1" class="default-btn">Read more about</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- This section for Home Demo - 12 -->
<div
    class="ds-about-area pb-100 d-none"
    [ngClass]="{'d-block': router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="ds-about-image">
                    <img src="assets/img/data-science/about/about.png" alt="image">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="ds-about-content">
                    <span class="sub">About dibiz</span>
                    <h3>We bring the power of data science <span>& analytics to business</span></h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit illo ducimus vero vero corporis voluptates beatae pariatur laudantium fugiat illum deserunt nostrum aliquid quisquam ess voluptatibus quia velit numquam esse porro ipsum dolor sit amet consectetur.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="inner-card">
                                <div class="icon">
                                    <img src="assets/img/data-science/about/icon1.svg" alt="image">
                                </div>
                                <h4>100% <span>Nature friendly</span></h4>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="inner-card">
                                <div class="icon">
                                    <img src="assets/img/data-science/about/icon2.svg" alt="image">
                                </div>
                                <h4>World <span>express services</span></h4>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class='bx bx-check-circle'></i> Collect every data</li>
                                <li><i class='bx bx-check-circle'></i> Import data for real-time</li>
                                <li><i class='bx bx-check-circle'></i> Travel & destinations</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class='bx bx-check-circle'></i> Health care & medicine</li>
                                <li><i class='bx bx-check-circle'></i> Financial & banking</li>
                                <li><i class='bx bx-check-circle'></i> Advertise & digital marketing</li>
                            </ul>
                        </div>
                    </div>
                    <div class="about-btn">
                        <a routerLink="/" class="default-btn">Read more <i class='flaticon-chevron'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ds-about-shape">
        <img src="assets/img/data-science/about/shape.png" alt="image">
    </div>
</div>