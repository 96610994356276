<!-- Start Footer Area -->
<footer
    class="footer-area"
    [ngClass]="{'d-none': router.url === '/home-eleven' || router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/entegi_logo_white.png" alt="logo" class="footer_logo">
                    </a>
                    <p>Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about-us-1">About</a></li>
                        <li><a routerLink="/pricing">Pricing</a></li>
                        <li><a routerLink="/portfolio-1">Portfolio</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-2">
                    <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/team-1">Team</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="/services-1">Services</a></li>
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/blog-1">Blog</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>175 5th Ave Premium Area, New York, NY 10010, United States</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+11234567890">+1 (123) 456 7890</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:hello&#64;plor.com">hello&#64;plor.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">+55 785 4578964</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p *ngFor="let Text of copyrightText;"><i class='bx bx-copyright'></i> {{Text.text}} <a target="_blank" href="{{Text.link}}">{{Text.linkText}}</a></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="terms-of-service">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape16"><img src="assets/img/shape/shape16.png" alt="image"></div>
</footer>

<footer
    class="footer-area it-footer-area d-none"
    [ngClass]="{'d-block': router.url === '/home-eleven'}"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/entegi_logo_white.png" alt="logo" class="footer_logo">
                    </a>
                    <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit eiusmod tempor incididunt labore dolore magna aliqua consec elite sed do labor.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Other pages</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/">About us</a></li>
                        <li><a routerLink="/">Services</a></li>
                        <li><a routerLink="/">Projects</a></li>
                        <li><a routerLink="/">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Helpful links</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Marketing strategy</a></li>
                        <li><a routerLink="/">Interior design</a></li>
                        <li><a routerLink="/">Digital services</a></li>
                        <li><a routerLink="/">Product design</a></li>
                        <li><a routerLink="/">Social marketing</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Support</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Privacy policy</a></li>
                        <li><a routerLink="/">Terms & uses</a></li>
                        <li><a routerLink="/">Support center</a></li>
                        <li><a routerLink="/">Community</a></li>
                        <li><a routerLink="/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Official info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class='bx bx-map'></i>
                            <span>Location:</span>
                            2976 sunrise road las vegas
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <span>Email:</span>
                            <a href="mailto:hello&#64;plor.com">hello&#64;plor.com</a>
                        </li>
                        <li>
                            <i class='bx bxs-phone'></i>
                            <span>Phone:</span>
                            <a href="tell:098765432150">098765432150</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <p *ngFor="let Text of copyrightText;"><i class='bx bx-copyright'></i> {{Text.text}} <a target="_blank" href="{{Text.link}}">{{Text.linkText}}</a></p>
        </div>
    </div>
</footer>

<footer
    class="footer-area ds-footer-area d-none"
    [ngClass]="{'d-block': router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/entegi_logo_white.png" alt="logo" class="footer_logo">
                    </a>
                    <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit eiusmod tempor incididunt labore dolore magna aliqua consec elite sed do labor.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Links</h3>
                    <ul class="footer-links-list">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">About us</a></li>
                        <li><a href="#">Services</a></li>
                        <li><a href="#">Projects</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Services</h3>
                    <ul class="footer-links-list">
                        <li><a href="#">Our pricing</a></li>
                        <li><a href="#">Team members</a></li>
                        <li><a href="#">Deep learning</a></li>
                        <li><a href="#">Data visualization</a></li>
                        <li><a href="#">Data analytics</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Support</h3>
                    <ul class="footer-links-list">
                        <li><a href="#">Privacy policy</a></li>
                        <li><a href="#">Terms & uses</a></li>
                        <li><a href="#">Support center</a></li>
                        <li><a href="#">Community</a></li>
                        <li><a href="#">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Official info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class='bx bx-map'></i>
                            <span>Location:</span>
                            2976 sunrise road las vegas
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <span>Email:</span>
                            <a href="mailto:hello&#64;plor.com">hello&#64;plor.com</a>
                        </li>
                        <li>
                            <i class='bx bxs-phone'></i>
                            <span>Phone:</span>
                            <a href="tell:098765432150">098765432150</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <p *ngFor="let Text of copyrightText;"><i class='bx bx-copyright'></i> {{Text.text}} <a target="_blank" href="{{Text.link}}">{{Text.linkText}}</a></p>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>