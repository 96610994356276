<div class="ds-funfacts-area pt-100 pb-70" style="background-image: url(assets/img/data-science/funfacts/funfacts-bg.jpg);">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="ds-funfacts-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/data-science/funfacts/data-mining.svg" alt="image">
                        </div>
                        <h3>
                            <span class="odometer" data-count="332">00</span>
                            <span class="plus">+</span>
                        </h3>
                        <p>Project completed</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="ds-funfacts-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/data-science/funfacts/algorithm.svg" alt="image">
                        </div>
                        <h3>
                            <span class="odometer" data-count="172">00</span>
                            <span class="plus">+</span>
                        </h3>
                        <p>Data management</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="ds-funfacts-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/data-science/funfacts/multiplication.svg" alt="image">
                        </div>
                        <h3>
                            <span class="odometer" data-count="215">00</span>
                            <span class="plus">+</span>
                        </h3>
                        <p>Managed analytics</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="ds-funfacts-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/data-science/funfacts/artificial-intelligence.svg" alt="image">
                        </div>
                        <h3>
                            <span class="odometer" data-count="725">00</span>
                            <span class="plus">+</span>
                        </h3>
                        <p>Data analytics</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>