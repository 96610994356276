<div
    class="plor-features-area pt-100 pb-70"
    [ngClass]="{'d-none': router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="plor-features-card wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                    <div class="features-image">
                        <a routerLink="/single-services">
                            <img src="assets/img/more-home/features/features-1.png" alt="image">
                        </a>
                    </div>
                    <h3>
                        <a routerLink="/single-services">Marketing Analysis</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="features-btn">View More <i class="flaticon-next-button"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="plor-features-card wow animate__animated animate__fadeInDown" data-wow-delay="200ms" data-wow-duration="1000ms">
                    <div class="features-image">
                        <a routerLink="/single-services">
                            <img src="assets/img/more-home/features/features-2.png" alt="image">
                        </a>
                    </div>
                    <h3>
                        <a routerLink="/single-services">Website Optimization</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="features-btn">View More <i class="flaticon-next-button"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="plor-features-card wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                    <div class="features-image">
                        <a routerLink="/single-services">
                            <img src="assets/img/more-home/features/features-3.png" alt="image">
                        </a>
                    </div>
                    <h3>
                        <a routerLink="/single-services">Email Marketing</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    <a routerLink="/single-services" class="features-btn">View More <i class="flaticon-next-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- This section for Home Demo - 12 -->
<div
    class="ds-features-area ptb-100 d-none"
    [ngClass]="{'d-block': router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-7 col-md-12">
                <div class="ds-features-content">
                    <span>Core features</span>
                    <h3>Are you ready for a better, more nice productive business</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit illo ducimus vero vero corporis voluptates beatae pariatur laudantium fugiat illum deserunt nostrum aliquid quisquam ess voluptatibus quia velit numquam esse porro ipsum dolor sit amet consectetur.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class='bx bx-check-circle'></i> Robotics</li>
                                <li><i class='bx bx-check-circle'></i> Real time tracking</li>
                                <li><i class='bx bx-check-circle'></i> Customer support</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class='bx bx-check-circle'></i> Testing code</li>
                                <li><i class='bx bx-check-circle'></i> Multi matrix code</li>
                                <li><i class='bx bx-check-circle'></i> Machine learning</li>
                            </ul>
                        </div>
                    </div>
                    <div class="features-btn">
                        <a routerLink="/" class="default-btn">Get started <i class='flaticon-chevron'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="ds-features-image">
                    <img src="assets/img/data-science/features.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="ds-features-shape">
        <img src="assets/img/data-science/dot-shape.png" alt="image">
    </div>
</div>