import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hometen-blog',
  templateUrl: './hometen-blog.component.html',
  styleUrls: ['./hometen-blog.component.scss']
})
export class HometenBlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
