<div class="it-choose-area ptb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="it-choose-image">
                    <img src="assets/img/startup-it-agency/choose.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="it-choose-content">
                    <span>Why choose us</span>
                    <h3>We best solutions for your business what we do see this</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit illo ducimus vero, vero corporis voluptates beatae pariatur quisquam ess voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur.</p>
                    <ul class="list">
                        <li><i class='bx bxs-chevron-down-circle'></i> Donec scelerisque egestas purus eget fringilla</li>
                        <li><i class='bx bxs-chevron-down-circle'></i> Ensu mauris imperdiet non ligula et lobortis re security</li>
                        <li><i class='bx bxs-chevron-down-circle'></i> Lorem ipsum dolor sit amet consectetur nod</li>
                    </ul>
                    <div class="choose-btn">
                        <a routerLink="/" class="default-btn">Join us now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="it-choose-shape">
        <img src="assets/img/startup-it-agency/choose-shape.png" alt="image">
    </div>
</div>