<section class="services-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">{{'text_all_services' | translate}}</span>
            <h2>{{'text_features_title' | translate}}</h2>
            <p>{{'text_features_detail' | translate}}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-megaphone"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">{{'text_features_box1' | translate}}</a></h3>
                    <p>{{'text_features_box1_detail' | translate}}</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-keywords"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">{{'text_features_box2' | translate}}</a></h3>
                    <p>{{'text_features_box2_detail' | translate}}</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-content-management"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">{{'text_features_box3' | translate}}</a></h3>
                    <p>{{'text_features_box1_detail' | translate}}</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-ppc"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">{{'text_features_box4' | translate}}</a></h3>
                    <p>{{'text_features_box4_detail' | translate}}</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-competitor"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">{{'text_features_box5' | translate}}</a></h3>
                    <p>{{'text_features_box5_detail' | translate}}
                    </p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-startup"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/single-services">{{'text_features_box6' | translate}}</a></h3>
                    <p>{{'text_features_box6_details' | translate}}</p>
                    <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> {{'text_more'|translate}} <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>