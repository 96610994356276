<div class="plor-get-started-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="plor-get-started-image wow animate__animated animate__fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
                    <img src="assets/img/more-home/get-started/get-started.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="plor-get-started-content wow animate__animated animate__fadeInRight" data-wow-delay="400ms" data-wow-duration="1000ms">
                    <span>GET STARTED</span>
                    <h3>We Like to Start Your Project With Us</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse ultrices gravida.</p>
                    <div class="get-started-btn">
                        <a routerLink="/contact" class="default-btn-with-radius">Get Started <i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>