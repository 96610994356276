<div class="startup-it-agency-color-css">
    <app-homeeleven-banner></app-homeeleven-banner>

    <app-easy-solution></app-easy-solution>

    <app-about></app-about>

    <app-services></app-services>

    <app-appointment></app-appointment>

    <app-case-studies></app-case-studies>

    <app-partner></app-partner>
    
    <app-feedback></app-feedback>

    <app-team-style-five></app-team-style-five>

    <app-why-choose-us></app-why-choose-us>

    <app-blog></app-blog>
</div>