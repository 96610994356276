import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials-style-two',
  templateUrl: './testimonials-style-two.component.html',
  styleUrls: ['./testimonials-style-two.component.scss']
})
export class TestimonialsStyleTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
