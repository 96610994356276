<div class="home-slides-two owl-carousel owl-theme">
    <div class="banner-section">
        <div class="container">
            <div class="home-content">
                <img src="assets/img/banner-img6.png" alt="image">
                <span class="sub-title">Digital Marketing</span>
                <h1>Boots Your Website Traffic</h1>
                <p>Get all kinds of hi-tech decesion making consulting services using Artificial Intelligence and Machine Learning from one umbrella.</p>
                <a routerLink="/about-us-1" class="default-btn">{{'text_more'|translate}}</a>
            </div>
        </div>

        <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
        <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
        <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    </div>

    <div class="banner-section">
        <div class="container">
            <div class="home-content">
                <img src="assets/img/banner-img7.png" alt="image">
                <span class="sub-title">SEO Marketing</span>
                <h1>Digital Marketing Specialists</h1>
                <p>Get all kinds of hi-tech decesion making consulting services using Artificial Intelligence and Machine Learning from one umbrella.</p>
                <a routerLink="/about-us-2" class="default-btn">{{'text_more'|translate}}</a>
            </div>
        </div>

        <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
        <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
        <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    </div>
</div>