<div class="easy-solution-area pt-100 pb-70">
    <div class="container">
        <div class="startup-it-agency-title">
            <span>Welcome to Plor</span>
            <h2>Easy solution for your IT problems</h2>
            <p>Lorem ipsum dolor sit amet consectetur nod adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua lonm andhn dolore magna labore.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="easy-solution-card">
                    <div class="solution-image">
                        <img src="assets/img/startup-it-agency/solution/solution1.jpg" alt="image">
                    </div>
                    <div class="solution-content">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/startup-it-agency/solution/code.svg" alt="image">
                            </div>
                            <h3>Manage IT service</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="easy-solution-card">
                    <div class="solution-image">
                        <img src="assets/img/startup-it-agency/solution/solution2.jpg" alt="image">
                    </div>
                    <div class="solution-content">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/startup-it-agency/solution/key.svg" alt="image">
                            </div>
                            <h3>Cyber security service</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="easy-solution-card">
                    <div class="solution-image">
                        <img src="assets/img/startup-it-agency/solution/solution3.jpg" alt="image">
                    </div>
                    <div class="solution-content">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/startup-it-agency/solution/magnifying-glass.svg" alt="image">
                            </div>
                            <h3>Digital experience</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>