import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homenine-about',
  templateUrl: './homenine-about.component.html',
  styleUrls: ['./homenine-about.component.scss']
})
export class HomenineAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
