import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  apiUrl = environment.apiURL;
  timezone = new Date().getTimezoneOffset();

  constructor(
    private http: HttpClient
  ) { }

  async post<T>(url: string, model: T,
    hasToken: boolean = true,
    acceptProfile: boolean = true,
    contentProfile: boolean = true,
    upsert: boolean = false): Promise<any> {


    let headers: HttpHeaders = new HttpHeaders();
    let header: any = [];
    headers = headers.append('timezone', this.timezone.toString());

    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Prefer', 'return=representation');

    if (hasToken)
      headers = headers.append('Authorization', 'Bearer ' + String(sessionStorage.getItem("token")));

    if (contentProfile)
      headers = headers.append('Content-Profile', 'web');
    else
      headers = headers.append('Content-Profile','basic_auth');

    if (acceptProfile)
      headers = headers.append('Accept-Profile', 'web');
    else
      headers = headers.append('Accept-Profile','basic_auth');

    if (upsert)
      headers = headers.append('Prefer', "resolution=merge-duplicates");



    const result = await this.http.post(this.apiUrl + url, model, { headers: headers }).toPromise().catch((error) => {
      //const errorSub = throwError (error);
      //errorSub.subscribe();
    });
    return result;
  }

  async patch<T>(url: string, model: T,
    hasToken: boolean = true,
    acceptProfile: boolean = true,
    contentProfile: boolean = true,
    upsert: boolean = false): Promise<any> {


    let headers: HttpHeaders = new HttpHeaders();
    let header: any = [];
    headers = headers.append('timezone', this.timezone.toString());
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Prefer', 'return=representation');

    if (hasToken)
      headers = headers.append('Authorization', 'Bearer ' + String(sessionStorage.getItem("token")));

    if (contentProfile)
      headers = headers.append('Content-Profile', 'web');

    if (acceptProfile)
      headers = headers.append('Accept-Profile', 'web');

    if (upsert)
      headers = headers.append('Prefer', "resolution=merge-duplicates");

    const result = await this.http.patch(this.apiUrl + url, model, { headers: headers }).toPromise().catch((error) => {
      //const errorSub = throwError (error);
      //errorSub.subscribe();
    });

    return result;
  }

  async get<T>(url: string, oneItem: boolean = false,schema = 'web'): Promise<any> {

    let headers: HttpHeaders = new HttpHeaders();
    let header: any = [];
    headers = headers.append('timezone', this.timezone.toString());
    headers = headers.append('Accept-Profile', schema);
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + String(sessionStorage.getItem("token")));
    headers = headers.append('Prefer', 'count=exact');

    if (oneItem)
      headers = headers.append('Accept', 'application/vnd.pgrst.object+json');

    const data = await this.http.get(this.apiUrl + url, { headers: headers }).toPromise().catch((error) => {
      //const errorSub = throwError (error);
      //errorSub.subscribe();
    });
    return data;
  }

  async getWidthPagination<T>(url: string, schema = 'web'): Promise<Observable<any>> {

    let headers: HttpHeaders = new HttpHeaders();
    let header: any = [];
    headers = headers.append('timezone', this.timezone.toString());
    headers = headers.append('Accept-Profile', schema);
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + String(sessionStorage.getItem("token")));
    headers = headers.append('Prefer', 'count=exact');

    return await this.http.get(this.apiUrl + url, { headers: headers, observe: 'response' });
  }

  async delete<T>(url: string): Promise<any> {

    let headers: HttpHeaders = new HttpHeaders();
    let header: any = [];
    headers = headers.append('timezone', this.timezone.toString());
    headers = headers.append('Accept-Profile', 'web');
    headers = headers.append('Content-Profile', 'web');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + String(sessionStorage.getItem("token")));
    headers = headers.append('Access-Control-Allow-Origin', '*');

    const data = await this.http.delete(this.apiUrl + url, { headers: headers }).toPromise().catch((error) => {

    });
    return data;
  }

}
