<div
    class="container"
    [ngClass]="{'d-none': router.url === '/home-eleven'}"
>
    <div class="row align-items-center">
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/customers/losbanditos.png" alt="image">
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/customers/peticim.png" alt="image">
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/customers/naturbes.png" alt="image">
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/customers/ofismaster.png" alt="image">
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                
            </div>
        </div>
    </div>
</div>

<!-- This section for Home Demo - 11 -->
<div
    class="it-partner-area pb-70 d-none"
    [ngClass]="{'d-block': router.url === '/home-eleven'}"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                <div class="it-partner-item">
                    <img src="assets/img/startup-it-agency/partner/partner1.png" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                <div class="it-partner-item">
                    <img src="assets/img/startup-it-agency/partner/partner2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                <div class="it-partner-item">
                    <img src="assets/img/startup-it-agency/partner/partner3.png" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                <div class="it-partner-item">
                    <img src="assets/img/startup-it-agency/partner/partner4.png" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                <div class="it-partner-item">
                    <img src="assets/img/startup-it-agency/partner/partner5.png" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                <div class="it-partner-item">
                    <img src="assets/img/startup-it-agency/partner/partner6.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>