<section class="banner-area">
    <div class="container">
        <div class="main-banner-content">
            <span class="sub-title">{{'text_home_growth_business' | translate}}</span>
            <h1>{{'text_marketplace_integration'|translate}}</h1>
            <p>{{'text_home_message1'|translate}}</p>
            <a routerLink="/contact" class="default-btn">{{'text_get_started'|translate}}</a>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
</section>