<div class="pb-70 plor-video-area">

    <div class="pb-100">
        <div class="container">
            <div class="plor-video-content wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                <img src="assets/img/more-home/video/video-2.jpg" class="with-border-radius" alt="image">       
                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube">
                    <i class="flaticon-play-button"></i>
                </a>
            </div>
        </div>
    </div>
    
    <div class="plor-partner-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="plor-partner-item wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                        <a href="#"><img src="assets/img/more-home/partner/partner-1.png" alt="image"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="plor-partner-item wow animate__animated animate__fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                        <a href="#"><img src="assets/img/more-home/partner/partner-2.png" alt="image"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="plor-partner-item wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                        <a href="#"><img src="assets/img/more-home/partner/partner-3.png" alt="image"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="plor-partner-item wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                        <a href="#"><img src="assets/img/more-home/partner/partner-4.png" alt="image"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="plor-partner-item wow animate__animated animate__fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms">
                        <a href="#"><img src="assets/img/more-home/partner/partner-5.png" alt="image"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="plor-partner-item wow animate__animated animate__fadeInUp" data-wow-delay="600ms" data-wow-duration="1000ms">
                        <a href="#"><img src="assets/img/more-home/partner/partner-6.png" alt="image"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>