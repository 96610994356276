<div
    class="container"
    [ngClass]="{'d-none': router.url === '/home-twelve'}"
>
    <div class="subscribe-content">
        <span class="sub-title">Plor Updates</span>
        <h2>Subscribe To Our Newsletter</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <form class="newsletter-form">
            <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
            <button type="submit" class="default-btn">Subscribe Now</button>
        </form>
    </div>
</div>

<!-- This section for Home Demo - 12 -->
<div
    class="ds-subscribe-area d-none"
    [ngClass]="{'d-block': router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="ds-subscribe-inner-box ptb-100">
            <div class="ds-subscribe-content">
                <span class="sub-title">Subscribe our newsletter</span>
                <h2>Get latest update and news first</h2>
                <form class="newsletter-form">
                    <input type="text" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                    <button type="submit" class="default-btn">Subscribe</button>
                </form>
            </div>
            <div class="subscribe-shape">
                <img src="assets/img/data-science/subscribe-shape.png" alt="image">
            </div>
        </div>
    </div>
</div>