import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  private scripts: ScriptModel[] = [];
  private styles: ScriptModel[] = [];

  public load(script: ScriptModel, toHead?: boolean): Observable<ScriptModel> {

    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      const existingScript = this.scripts.find(s => s.name === script.name);
      // Complete if already loaded
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      }
      else {
        // Add the script
        this.scripts = [...this.scripts, script];

        // Load the script
        let scriptElement = document.createElement("script");
        scriptElement.type = "text/javascript";
        scriptElement.src = script.src;
        scriptElement.id = script.name;

        scriptElement.onload = () => {
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (error: any) => {
          observer.error("Couldn't load script " + script.src);
        };

        if(toHead){
          document.head.appendChild(scriptElement);
        }else{
          document.getElementsByTagName('body')[0].appendChild(scriptElement);
        }
      }
    });
  }

  public loadStyle(style: ScriptModel, prepend: boolean): Observable<ScriptModel> {

    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      var existingStyle = this.styles.find(s => s.name == style.name);
      // Complete if already loaded
      if (existingStyle && existingStyle.loaded) {
        observer.next(existingStyle);
        observer.complete();
      }
      else {
        // Add the style
        this.scripts = [...this.styles, style];

        // Load the style
        let styleElement = document.createElement("link");
        styleElement.type = "text/css";
        styleElement.rel = "stylesheet";
        styleElement.href = style.src;
        styleElement.crossOrigin = "anonymous";

        (prepend) ? document.getElementsByTagName('head')[0].prepend(styleElement) : document.getElementsByTagName('head')[0].appendChild(styleElement);
        style.loaded = true;
        observer.next(style);
        observer.complete();
      }
    });
  }
}

export interface ScriptModel {
  name: string,
  src: string,
  loaded: boolean
}
