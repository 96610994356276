<section class="plor-subscribe-area bg-F7F8FF ptb-100">
    <div class="container">
        <div class="plor-subscribe-content wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
            <span class="sub-title">PLOR UPDATES</span>
            <h2>Subscribe To Our Newsletter</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            <form class="newsletter-form" data-bs-toggle="validator">
                <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                <button type="submit" class="default-btn-with-radius">Subscribe Now <i class="flaticon-next-button"></i></button>
                <div id="validator-newsletter" class="form-result"></div>
            </form>
        </div>
    </div>
    <div class="plor-subscribe-shape-1">
        <img src="assets/img/more-home/subscribe/shape-1.png" alt="image">
    </div>
    <div class="plor-subscribe-shape-2">
        <img src="assets/img/more-home/subscribe/shape-2.png" alt="image">
    </div>
</section>