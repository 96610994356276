<div
    class="container"
    [ngClass]="{'d-none': router.url === '/home-eleven' || router.url === '/home-twelve'}"
>
    <div class="section-title">
        <span class="sub-title">Our Blog</span>
        <h2>Our Latest Media</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="assets/img/blog/blog-img1.jpg" alt="image">
                    </a>
                </div>
                <div class="content">
                    <h3><a routerLink="/single-blog-1">Digital Marketing Agency Blogs You Should Read</a></h3>
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user1.jpg" alt="image">
                        <div class="info">
                            <h5>David Smith</h5>
                            <span>Jun 21, 2024</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="assets/img/blog/blog-img2.jpg" alt="image">
                    </a>
                </div>
                <div class="content">
                    <h3><a routerLink="/single-blog-1">Digital Marketing Strategies for Lead Generation</a></h3>
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user2.jpg" alt="image">
                        <div class="info">
                            <h5>Sarah Taylor</h5>
                            <span>Jun 20, 2024</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="assets/img/blog/blog-img3.jpg" alt="image">
                    </a>
                </div>
                <div class="content">
                    <h3><a routerLink="/single-blog-1">Agencies Can Successfully Recover From COVID?</a></h3>
                    <div class="d-flex align-items-center">
                        <img src="assets/img/user3.jpg" alt="image">
                        <div class="info">
                            <h5>Steven Gibson</h5>
                            <span>Jun 19, 2024</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- This section for Home Demo - 11 -->
<div
    class="it-blog-area pt-100 pb-70 d-none"
    [ngClass]="{'d-block': router.url === '/home-eleven'}"
>
    <div class="container">
        <div class="startup-it-agency-title">
            <span>Our blog</span>
            <h2>Read our latest insights</h2>
            <p>Lorem ipsum dolor sit amet consectetur nod adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua lonm andhn dolore magna labore.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="it-blog-card">
                    <div class="blog-image">
                        <a routerLink="/single-blog-1"><img src="assets/img/startup-it-agency/blog/blog1.jpg" alt="image"></a>
                    </div>
                    <div class="blog-content">
                        <div class="date">
                            <b>12</b>
                            <span>April</span>
                        </div>
                        <ul class="meta">
                            <li><i class='bx bx-user'></i> By <a routerLink="/blog-1">admin</a></li>
                            <li>/</li>
                            <li><i class='bx bx-message-rounded-dots'></i> No comment</li>
                        </ul>
                        <h3>
                            <a routerLink="/single-blog-1">How is technology working with new things?</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed tempor incididunt labore et dolore magna aliqua minim.</p>
                        <a routerLink="/single-blog-1" class="blog-btn">Read more</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="it-blog-card">
                    <div class="blog-image">
                        <a routerLink="/single-blog-1"><img src="assets/img/startup-it-agency/blog/blog2.jpg" alt="image"></a>
                    </div>
                    <div class="blog-content">
                        <div class="date">
                            <b>13</b>
                            <span>April</span>
                        </div>
                        <ul class="meta">
                            <li><i class='bx bx-user'></i> By <a routerLink="/blog-1">admin</a></li>
                            <li>/</li>
                            <li><i class='bx bx-message-rounded-dots'></i> No comment</li>
                        </ul>
                        <h3>
                            <a routerLink="/single-blog-1">Top 10 important tips IT services & technology</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed tempor incididunt labore et dolore magna aliqua minim.</p>
                        <a routerLink="/single-blog-1" class="blog-btn">Read more</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="it-blog-card">
                    <div class="blog-image">
                        <a routerLink="/single-blog-1"><img src="assets/img/startup-it-agency/blog/blog3.jpg" alt="image"></a>
                    </div>
                    <div class="blog-content">
                        <div class="date">
                            <b>14</b>
                            <span>April</span>
                        </div>
                        <ul class="meta">
                            <li><i class='bx bx-user'></i> By <a routerLink="/blog-1">admin</a></li>
                            <li>/</li>
                            <li><i class='bx bx-message-rounded-dots'></i> No comment</li>
                        </ul>
                        <h3>
                            <a routerLink="/single-blog-1">New device invention for the digital platform</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed tempor incididunt labore et dolore magna aliqua minim.</p>
                        <a routerLink="/single-blog-1" class="blog-btn">Read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- This section for Home Demo - 12 -->
<div
    class="ds-blog-area pt-100 pb-70 d-none"
    [ngClass]="{'d-block': router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="ds-section-title">
            <span>Blog</span>
            <h2>Read our latest posts</h2>
            <p>Integer vel elit congue accumsan ligula placerat mi uisque tempor sapien justo sed accumsan tortor ultrices nec.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="ds-blog-card">
                    <div class="blog-image">
                        <a routerLink="/single-blog-1"><img src="assets/img/data-science/blog/blog1.jpg" alt="image"></a>
                        <div class="date">
                            <b>12</b>
                            <span>May</span>
                        </div>
                    </div>
                    <div class="blog-content">
                        <ul class="meta">
                            <li><i class='bx bx-user'></i> By <a routerLink="/blog-1">admin</a></li>
                            <li>/</li>
                            <li><i class='bx bx-message-rounded-dots'></i> No comment</li>
                        </ul>
                        <h3>
                            <a routerLink="/single-blog-1">Making peace with the feast or famine of freelancing</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed tempor incididunt labore et dolore magna aliqua minim.</p>
                        <a routerLink="/single-blog-1" class="blog-btn">Read more</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="ds-blog-card">
                    <div class="blog-image">
                        <a routerLink="/single-blog-1"><img src="assets/img/data-science/blog/blog2.jpg" alt="image"></a>
                        <div class="date">
                            <b>13</b>
                            <span>May</span>
                        </div>
                    </div>
                    <div class="blog-content">
                        <ul class="meta">
                            <li><i class='bx bx-user'></i> By <a routerLink="/blog-1">admin</a></li>
                            <li>/</li>
                            <li><i class='bx bx-message-rounded-dots'></i> No comment</li>
                        </ul>
                        <h3>
                            <a routerLink="/single-blog-1">Instagram feed add to your WordPress site</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed tempor incididunt labore et dolore magna aliqua minim.</p>
                        <a routerLink="/single-blog-1" class="blog-btn">Read more</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="ds-blog-card">
                    <div class="blog-image">
                        <a routerLink="/single-blog-1"><img src="assets/img/data-science/blog/blog3.jpg" alt="image"></a>
                        <div class="date">
                            <b>14</b>
                            <span>May</span>
                        </div>
                    </div>
                    <div class="blog-content">
                        <ul class="meta">
                            <li><i class='bx bx-user'></i> By <a routerLink="/blog-1">admin</a></li>
                            <li>/</li>
                            <li><i class='bx bx-message-rounded-dots'></i> No comment</li>
                        </ul>
                        <h3>
                            <a routerLink="/single-blog-1">I used the web for a day on a 50 mb budget</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed tempor incididunt labore et dolore magna aliqua minim.</p>
                        <a routerLink="/single-blog-1" class="blog-btn">Read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>