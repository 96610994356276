<div class="ds-solutions-area ptb-100">
    <div class="container">
        <div class="ds-section-title text-start">
            <span>More than solutions</span>
            <h2>Big data to improve business analysis</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Duis at dictum risus non suscipit arcu lorem ipsum sit amet quisque aliquam posuere tortor sit amet convallis nunc scelerisque.</p>
        </div>
        <div class="ds-solutions-list-tabs">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="data-analysis-tab" data-bs-toggle="tab" href="#data-analysis" role="tab" aria-controls="data-analysis"><img src="assets/img/data-science/solutions/data.svg" alt="image"> Data analysis</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="data-modules-tab" data-bs-toggle="tab" href="#data-modules" role="tab" aria-controls="data-modules"><img src="assets/img/data-science/solutions/various.svg" alt="image"> Data modules</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="social-assistant-tab" data-bs-toggle="tab" href="#social-assistant" role="tab" aria-controls="social-assistant"><img src="assets/img/data-science/solutions/production.svg" alt="image"> Social assistant</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="ai-solutions-tab" data-bs-toggle="tab" href="#ai-solutions" role="tab" aria-controls="ai-solutions"><img src="assets/img/data-science/solutions/global.svg" alt="image"> AI solutions</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="data-analysis" role="tabpanel">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-5 col-md-12">
                            <div class="ds-solutions-image">
                                <img src="assets/img/data-science/solutions/main.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12">
                            <div class="ds-solutions-content">
                                <h3>We bring the power of data science & analytics to business</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit illo ducimus vero vero corporis voluptates beatae pariatur laudantium fugiat illum deserunt nostrum aliquid quisquam ess voluptatibus quia velit numquam esse porro ipsum dolor sit amet consectetur.</p>
                                <ul class="list">
                                    <li><i class='bx bx-check-circle'></i> Nunc lacinia nisl vel mauris ultrices porta eget nec quam</li>
                                    <li><i class='bx bx-check-circle'></i> Curabitur vestibulum ac ipsum at pulvinar suspendisse in dui.</li>
                                    <li><i class='bx bx-check-circle'></i> Vestibulum gravida sapien vehicula dui efficitur convallis</li>
                                </ul>
                                <div class="solutions-btn">
                                    <a routerLink="/" class="default-btn">Read more <i class='flaticon-chevron'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="data-modules" role="tabpanel">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-5 col-md-12">
                            <div class="ds-solutions-image">
                                <img src="assets/img/data-science/solutions/main.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12">
                            <div class="ds-solutions-content">
                                <h3>We bring the power of data science & analytics to business</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit illo ducimus vero vero corporis voluptates beatae pariatur laudantium fugiat illum deserunt nostrum aliquid quisquam ess voluptatibus quia velit numquam esse porro ipsum dolor sit amet consectetur.</p>
                                <ul class="list">
                                    <li><i class='bx bx-check-circle'></i> Nunc lacinia nisl vel mauris ultrices porta eget nec quam</li>
                                    <li><i class='bx bx-check-circle'></i> Curabitur vestibulum ac ipsum at pulvinar suspendisse in dui.</li>
                                    <li><i class='bx bx-check-circle'></i> Vestibulum gravida sapien vehicula dui efficitur convallis</li>
                                </ul>
                                <div class="solutions-btn">
                                    <a routerLink="/" class="default-btn">Read more <i class='flaticon-chevron'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="social-assistant" role="tabpanel">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-5 col-md-12">
                            <div class="ds-solutions-image">
                                <img src="assets/img/data-science/solutions/main.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12">
                            <div class="ds-solutions-content">
                                <h3>We bring the power of data science & analytics to business</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit illo ducimus vero vero corporis voluptates beatae pariatur laudantium fugiat illum deserunt nostrum aliquid quisquam ess voluptatibus quia velit numquam esse porro ipsum dolor sit amet consectetur.</p>
                                <ul class="list">
                                    <li><i class='bx bx-check-circle'></i> Nunc lacinia nisl vel mauris ultrices porta eget nec quam</li>
                                    <li><i class='bx bx-check-circle'></i> Curabitur vestibulum ac ipsum at pulvinar suspendisse in dui.</li>
                                    <li><i class='bx bx-check-circle'></i> Vestibulum gravida sapien vehicula dui efficitur convallis</li>
                                </ul>
                                <div class="solutions-btn">
                                    <a routerLink="/" class="default-btn">Read more <i class='flaticon-chevron'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="ai-solutions" role="tabpanel">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-5 col-md-12">
                            <div class="ds-solutions-image">
                                <img src="assets/img/data-science/solutions/main.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12">
                            <div class="ds-solutions-content">
                                <h3>We bring the power of data science & analytics to business</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit illo ducimus vero vero corporis voluptates beatae pariatur laudantium fugiat illum deserunt nostrum aliquid quisquam ess voluptatibus quia velit numquam esse porro ipsum dolor sit amet consectetur.</p>
                                <ul class="list">
                                    <li><i class='bx bx-check-circle'></i> Nunc lacinia nisl vel mauris ultrices porta eget nec quam</li>
                                    <li><i class='bx bx-check-circle'></i> Curabitur vestibulum ac ipsum at pulvinar suspendisse in dui.</li>
                                    <li><i class='bx bx-check-circle'></i> Vestibulum gravida sapien vehicula dui efficitur convallis</li>
                                </ul>
                                <div class="solutions-btn">
                                    <a routerLink="/" class="default-btn">Read more <i class='flaticon-chevron'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ds-solutions-shape">
        <img src="assets/img/data-science/dot-shape.png" alt="image">
    </div>
</div>