<app-homeeight-main-banner></app-homeeight-main-banner>

<app-features></app-features>

<app-homeeight-about></app-homeeight-about>

<app-what-we-do-style-two></app-what-we-do-style-two>

<app-services></app-services>

<app-team-style-three></app-team-style-three>

<app-video></app-video>

<app-pricing-style-two></app-pricing-style-two>

<app-testimonials-style-two></app-testimonials-style-two>

<app-homeeight-blog></app-homeeight-blog>

<app-subscribe-style-two></app-subscribe-style-two>