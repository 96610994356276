<div
    class="container"
    [ngClass]="{'d-none': router.url === '/home-twelve'}"
>
    <div class="section-title">
        <span class="sub-title">Pricing</span>
        <h2>Our Flexible Pricing Plan</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-pricing-box">
                <div class="pricing-header">
                    <div class="icon">
                        <i class="flaticon-paper-plane"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                        </div>
                    </div>
                    <h3>Starter Plan</h3>
                </div>
                <div class="pricing-features">
                    <ul>
                        <li>10GB Bandwidth Internet</li>
                        <li>Business & Financ Analysing</li>
                        <li>25 Social Media Reviews</li>
                        <li>Customer Managemet</li>
                        <li>24/7 Support</li>
                    </ul>
                </div>
                <div class="price">
                    $49.99
                    <span>Per Month</span>
                </div>
                <a routerLink="/" class="default-btn">Book Now</a>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-pricing-box">
                <div class="pricing-header">
                    <div class="icon">
                        <i class="flaticon-jigsaw"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                        </div>
                    </div>
                    <h3>Advance Plan</h3>
                </div>
                <div class="pricing-features">
                    <ul>
                        <li>15GB Bandwidth Internet</li>
                        <li>Business & Financ Analysing</li>
                        <li>30 Social Media Reviews</li>
                        <li>Customer Managemet</li>
                        <li>24/7 Support</li>
                    </ul>
                </div>
                <div class="price">
                    $69.99
                    <span>Per Month</span>
                </div>
                <a routerLink="/" class="default-btn">Book Now</a>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-pricing-box">
                <div class="pricing-header">
                    <div class="icon">
                        <i class="flaticon-diamond"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                        </div>
                    </div>
                    <h3>Premium Plan</h3>
                </div>
                <div class="pricing-features">
                    <ul>
                        <li>50GB Bandwidth Internet</li>
                        <li>Business & Financ Analysing</li>
                        <li>35 Social Media Reviews</li>
                        <li>Customer Managemet</li>
                        <li>24/7 Support</li>
                    </ul>
                </div>
                <div class="price">
                    $99.99
                    <span>Per Month</span>
                </div>
                <a routerLink="/" class="default-btn">Book Now</a>
            </div>
        </div>
    </div>
</div>

<!-- This section for Home Demo - 12 -->
<div
    class="ds-pricing-area pt-100 pb-70 d-none"
    [ngClass]="{'d-block': router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="ds-section-title">
            <span>Pricing</span>
            <h2>Choose your pricing plan</h2>
            <p>Integer vel elit congue accumsan ligula placerat mi uisque tempor sapien justo sed accumsan tortor ultrices nec.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="ds-pricing-card">
                    <h3>Basic plan</h3>
                    <div class="price">$70 <span> / Month</span></div>
                    <ul class="list">
                        <li class="d-flex align-items-center justify-content-between">
                            Managed analytics
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Business intelligence
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            International payments
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Data management
                            <i class='bx bx-x'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Social integration
                            <i class='bx bx-x'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Relevant app content
                            <i class='bx bx-x'></i>
                        </li>
                    </ul>
                    <div class="pricing-btn">
                        <a routerLink="/" class="default-btn">Get start now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="ds-pricing-card">
                    <h3>Medium plan</h3>
                    <div class="price">$89 <span> / Month</span></div>
                    <ul class="list">
                        <li class="d-flex align-items-center justify-content-between">
                            Managed analytics
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Business intelligence
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            International payments
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Data management
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Social integration
                            <i class='bx bx-x'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Relevant app content
                            <i class='bx bx-x'></i>
                        </li>
                    </ul>
                    <div class="pricing-btn">
                        <a routerLink="/" class="default-btn">Get start now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="ds-pricing-card">
                    <h3>Advance plan</h3>
                    <div class="price">$100 <span> / Month</span></div>
                    <ul class="list">
                        <li class="d-flex align-items-center justify-content-between">
                            Managed analytics
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Business intelligence
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            International payments
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Data management
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Social integration
                            <i class='bx bx-check'></i>
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            Relevant app content
                            <i class='bx bx-check'></i>
                        </li>
                    </ul>
                    <div class="pricing-btn">
                        <a routerLink="/" class="default-btn">Get start now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>