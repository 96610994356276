<div
    class="container"
    [ngClass]="{'d-none': router.url === '/home-eleven' || router.url === '/home-twelve'}"
>
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="feedback-image">
                <img src="assets/img/marketplace_integrations_origami1.jpeg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="feedback-content">
                <span class="sub-title">{{'text_home_customer_comments' | translate}}</span>
                <h2>{{'text_home_customer_title' | translate}}</h2>
                <div class="feedback-slides owl-carousel owl-theme">
                    <div class="single-feedback-item">
                        <p>{{'text_customer_comments1'| translate}}</p>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="assets/img/customer1.jpg" alt="image">
                                <div class="title">
                                    <h3>Gökhan Uyar</h3>
                                    <span>Besta Güvenlik - Yönetici</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-feedback-item">
                        <p>{{'text_customer_comments2' | translate}}</p>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="assets/img/customer2.jpg" alt="image">
                                <div class="title">
                                    <h3>Yağmur Akyüz</h3>
                                    <span>Bebek Şekerleri - Yönetici</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-feedback-item">
                        <p>{{'text_customer_comments3' | translate}}</p>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="assets/img/customer3.webp" alt="image">
                                <div class="title">
                                    <h3>Ela Demir</h3>
                                    <span>Bahar Çiçekçilik - Yönetici</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- This section for Home Demo - 11 -->
<div
    class="it-feedback-area ptb-100 d-none"
    [ngClass]="{'d-block': router.url === '/home-eleven'}"
>
    <div class="container">
        <div class="startup-it-agency-title">
            <span>Client reviews</span>
            <h2>Feedback from our clients</h2>
            <p>Lorem ipsum dolor sit amet consectetur nod adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua lonm andhn dolore magna labore.</p>
        </div>
        <div class="it-feedback-slides owl-carousel owl-theme">
            <div class="it-feedback-card">
                <div class="info">
                    <img src="assets/img/user1.jpg" alt="image">
                    <div class="title">
                        <h3>Abraham linkon</h3>
                        <span>Graphics designer</span>
                    </div>
                </div>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis soluta aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam.”</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
            </div>
            <div class="it-feedback-card">
                <div class="info">
                    <img src="assets/img/user2.jpg" alt="image">
                    <div class="title">
                        <h3>Amanda brown</h3>
                        <span>Chief officer</span>
                    </div>
                </div>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis soluta aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam.”</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
            </div>
            <div class="it-feedback-card">
                <div class="info">
                    <img src="assets/img/user3.jpg" alt="image">
                    <div class="title">
                        <h3>Mike wazowski</h3>
                        <span>Analytic officer</span>
                    </div>
                </div>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis soluta aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam.”</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
            </div>
            <div class="it-feedback-card">
                <div class="info">
                    <img src="assets/img/user1.jpg" alt="image">
                    <div class="title">
                        <h3>Abraham linkon</h3>
                        <span>Graphics designer</span>
                    </div>
                </div>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis soluta aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam.”</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
            </div>
            <div class="it-feedback-card">
                <div class="info">
                    <img src="assets/img/user2.jpg" alt="image">
                    <div class="title">
                        <h3>Amanda brown</h3>
                        <span>Chief officer</span>
                    </div>
                </div>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis soluta aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam.”</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
            </div>
            <div class="it-feedback-card">
                <div class="info">
                    <img src="assets/img/user3.jpg" alt="image">
                    <div class="title">
                        <h3>Mike wazowski</h3>
                        <span>Analytic officer</span>
                    </div>
                </div>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis soluta aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam.”</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
            </div>
            <div class="it-feedback-card">
                <div class="info">
                    <img src="assets/img/user1.jpg" alt="image">
                    <div class="title">
                        <h3>Abraham linkon</h3>
                        <span>Graphics designer</span>
                    </div>
                </div>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis soluta aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam.”</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
            </div>
            <div class="it-feedback-card">
                <div class="info">
                    <img src="assets/img/user2.jpg" alt="image">
                    <div class="title">
                        <h3>Amanda brown</h3>
                        <span>Chief officer</span>
                    </div>
                </div>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis soluta aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam.”</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
            </div>
            <div class="it-feedback-card">
                <div class="info">
                    <img src="assets/img/user3.jpg" alt="image">
                    <div class="title">
                        <h3>Mike wazowski</h3>
                        <span>Analytic officer</span>
                    </div>
                </div>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis soluta aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam.”</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="it-feedback-shape">
        <img src="assets/img/startup-it-agency/feedback-shape.png" alt="image">
    </div>
</div>

<!-- This section for Home Demo - 12 -->
<div
    class="ds-feedback-area pt-100 d-none"
    [ngClass]="{'d-block': router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="ds-section-title text-start">
            <h2>What our client say</h2>
            <p>Integer vel elit congue accumsan ligula placerat mi uisque tempor sapien justo sed accumsan tortor ultrices nec.</p>
        </div>
        <div class="ds-feedback-slides owl-carousel owl-theme">
            <div class="ds-feedback-card">
                <div class="info">
                    <img src="assets/img/user1.jpg" alt="image">
                    <div class="title">
                        <h3>Luise henrikes</h3>
                        <span>CEO founder</span>
                    </div>
                </div>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam qui excepturi dolore magna.”</p>
            </div>
            <div class="ds-feedback-card">
                <div class="info">
                    <img src="assets/img/user2.jpg" alt="image">
                    <div class="title">
                        <h3>Jhoni habard</h3>
                        <span>Web designer</span>
                    </div>
                </div>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam qui excepturi dolore magna.”</p>
            </div>
            <div class="ds-feedback-card">
                <div class="info">
                    <img src="assets/img/user3.jpg" alt="image">
                    <div class="title">
                        <h3>Jara lopez</h3>
                        <span>Manager</span>
                    </div>
                </div>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam qui excepturi dolore magna.”</p>
            </div>
            <div class="ds-feedback-card">
                <div class="info">
                    <img src="assets/img/user1.jpg" alt="image">
                    <div class="title">
                        <h3>Luise henrikes</h3>
                        <span>CEO founder</span>
                    </div>
                </div>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam qui excepturi dolore magna.”</p>
            </div>
            <div class="ds-feedback-card">
                <div class="info">
                    <img src="assets/img/user2.jpg" alt="image">
                    <div class="title">
                        <h3>Jhoni habard</h3>
                        <span>Web designer</span>
                    </div>
                </div>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam qui excepturi dolore magna.”</p>
            </div>
            <div class="ds-feedback-card">
                <div class="info">
                    <img src="assets/img/user3.jpg" alt="image">
                    <div class="title">
                        <h3>Jara lopez</h3>
                        <span>Manager</span>
                    </div>
                </div>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“Lorem ipsum do amet consectetur adipisicing perspiciatis aspernatur dolorum sequi quisquam ullam pariatur nihil dolorem cumque excepturi totam qui excepturi dolore magna.”</p>
            </div>
        </div>
    </div>
</div>