<div
    class="plor-case-studies-area ptb-100"
    [ngClass]="{'d-none': router.url === '/home-eleven'}"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="plor-case-studies-section-content wow animate__animated animate__fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                    <span>CASE STUDIES</span>
                    <h3>Check of Our Some Recent Works & Case Studies</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="plor-case-studies-slides owl-carousel owl-theme">
                    <div class="plor-case-studies-card wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                        <a href="single-portfolio.html"><img src="assets/img/more-home/case-studies/case-studies-1.jpg" alt="image"></a>
                        <div class="content">
                            <h3>
                                <a href="single-portfolio.html">Data Scientist</a>
                            </h3>
                            <div class="icon">
                                <a href="single-portfolio.html"><i class="flaticon-next-button"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="plor-case-studies-card wow animate__animated animate__fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                        <a href="single-portfolio.html"><img src="assets/img/more-home/case-studies/case-studies-2.jpg" alt="image"></a>
                        <div class="content">
                            <h3>
                                <a href="single-portfolio.html">Data Analysis</a>
                            </h3>
                            <div class="icon">
                                <a href="single-portfolio.html"><i class="flaticon-next-button"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="plor-case-studies-card wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                        <a href="single-portfolio.html"><img src="assets/img/more-home/case-studies/case-studies-3.jpg" alt="image"></a>
                        <div class="content">
                            <h3>
                                <a href="single-portfolio.html">Detection Project</a>
                            </h3>
                            <div class="icon">
                                <a href="single-portfolio.html"><i class="flaticon-next-button"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="plor-case-studies-card wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                        <a href="single-portfolio.html"><img src="assets/img/more-home/case-studies/case-studies-1.jpg" alt="image"></a>
                        <div class="content">
                            <h3>
                                <a href="single-portfolio.html">Data Scientist</a>
                            </h3>
                            <div class="icon">
                                <a href="single-portfolio.html"><i class="flaticon-next-button"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="plor-case-studies-card wow animate__animated animate__fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms">
                        <a href="single-portfolio.html"><img src="assets/img/more-home/case-studies/case-studies-2.jpg" alt="image"></a>
                        <div class="content">
                            <h3>
                                <a href="single-portfolio.html">Data Analysis</a>
                            </h3>
                            <div class="icon">
                                <a href="single-portfolio.html"><i class="flaticon-next-button"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="plor-case-studies-card wow animate__animated animate__fadeInUp" data-wow-delay="600ms" data-wow-duration="1000ms">
                        <a href="single-portfolio.html"><img src="assets/img/more-home/case-studies/case-studies-3.jpg" alt="image"></a>
                        <div class="content">
                            <h3>
                                <a href="single-portfolio.html">Detection Project</a>
                            </h3>
                            <div class="icon">
                                <a href="single-portfolio.html"><i class="flaticon-next-button"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- This section for Home Demo - 11 -->
<div
    class="case-study-area pb-100 d-none"
    [ngClass]="{'d-block': router.url === '/home-eleven'}"
>
    <div class="container">
        <div class="startup-it-agency-title style-two">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="div">
                        <span>Case study</span>
                        <h2>Introduce our projects and check recent work here</h2>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore dolore magna aliqua. quis adipisicing elit nostrud exercitation ullamco laboris.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="case-study-slides owl-carousel owl-theme">
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study1.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Marketing / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Web development</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study2.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Ideas / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Smart vision</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study3.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Marketing / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Virtual reality</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study4.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Ideas / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Tech solutions</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study5.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Marketing / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Platform intergation</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study1.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Marketing / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Web development</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study2.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Ideas / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Smart vision</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study3.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Marketing / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Virtual reality</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study4.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Ideas / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Tech solutions</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study5.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Marketing / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Platform intergation</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study1.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Marketing / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Web development</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study2.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Ideas / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Smart vision</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study3.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Marketing / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Virtual reality</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study4.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Ideas / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Tech solutions</a>
                    </h3>
                </div>
            </div>
            <div class="case-study-card">
                <div class="case-study-image">
                    <a routerLink="/single-portfolio"><img src="assets/img/startup-it-agency/case-study/case-study5.jpg" alt="image"></a>
                </div>
                <div class="case-study-content">
                    <span>Marketing / Design</span>
                    <h3>
                        <a routerLink="/single-portfolio">Platform intergation</a>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>