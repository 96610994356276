<div class="plor-overview-area pb-100">
    <div class="container-fluid">
        <div class="overview-animate-text">
            <span>We Provide Best <strong>Digital</strong> Marketing Solutions&nbsp;</span>
        </div>
    </div>
    <svg style="display:none;">
        <defs>
            <filter id="stroke-text">
                <feMorphology radius="1" operator="dilate"></feMorphology>
                <feComposite operator="xor" in="SourceGraphic"/>
            </filter>
        </defs>
    </svg>
</div>

<div class="plor-about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="plor-about-image wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                    <img src="assets/img/more-home/about/about-3.png" alt="image">
                    <div class="wrap-content">
                        <p>“Praesent sapien massa convallis a pellentesque nec egestas non nisi.”</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="plor-about-content wow animate__animated animate__fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                    <span>ABOUT US</span>
                    <h3>Enjoy Full-Service Digital Marketing Expertise</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse ultrices gravida risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <ul class="about-list">
                        <li>
                            <span>376</span> Completed Projects
                        </li>
                        <li>
                            <span>7548</span> Working Hours Were Spent
                        </li>
                        <li>
                            <span>570</span> Expert Team Members
                        </li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="/about-us-1" class="default-btn-with-radius">More About Us <i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>