<div class="plor-services-area bg-F3ECEA pt-100 pb-70">
    <div class="container">
        <div class="section-title with-gradient-color">
            <span class="sub-title">SERVICES</span>
            <h2>Let’s Check Our Services</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="plor-services-box wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                    <div class="icon">
                        <i class="flaticon-megaphone"></i>
                    </div>
                    <h3>
                        <a href="single-services.html">Social Media Marketing</a>
                    </h3>
                    <p>Nulla quis lorem ut libero malesuada feugiat sed porttitor lectus nibh. Proin eget tortor risus nulla quis lorem ut libero.</p>
                    <a href="single-services.html" class="services-btn">View More <i class="flaticon-next-button"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="plor-services-box wow animate__animated animate__fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                    <div class="icon">
                        <i class="flaticon-keywords"></i>
                    </div>
                    <h3>
                        <a href="single-services.html">Keyword Research</a>
                    </h3>
                    <p>Nulla quis lorem ut libero malesuada feugiat sed porttitor lectus nibh. Proin eget tortor risus nulla quis lorem ut libero.</p>
                    <a href="single-services.html" class="services-btn">View More <i class="flaticon-next-button"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="plor-services-box wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                    <div class="icon">
                        <i class="flaticon-content-management"></i>
                    </div>
                    <h3>
                        <a href="single-services.html">Content Marketing</a>
                    </h3>
                    <p>Nulla quis lorem ut libero malesuada feugiat sed porttitor lectus nibh. Proin eget tortor risus nulla quis lorem ut libero.</p>
                    <a href="single-services.html" class="services-btn">View More <i class="flaticon-next-button"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="plor-services-box wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                    <div class="icon">
                        <i class="flaticon-competitor"></i>
                    </div>
                    <h3>
                        <a href="single-services.html">Competitor Research</a>
                    </h3>
                    <p>Nulla quis lorem ut libero malesuada feugiat sed porttitor lectus nibh. Proin eget tortor risus nulla quis lorem ut libero.</p>
                    <a href="single-services.html" class="services-btn">View More <i class="flaticon-next-button"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="plor-services-box wow animate__animated animate__fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms">
                    <div class="icon">
                        <i class="flaticon-ppc"></i>
                    </div>
                    <h3>
                        <a href="single-services.html">PPC Advertising</a>
                    </h3>
                    <p>Nulla quis lorem ut libero malesuada feugiat sed porttitor lectus nibh. Proin eget tortor risus nulla quis lorem ut libero.</p>
                    <a href="single-services.html" class="services-btn">View More <i class="flaticon-next-button"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="plor-services-box wow animate__animated animate__fadeInUp" data-wow-delay="600ms" data-wow-duration="1000ms">
                    <div class="icon">
                        <i class="flaticon-startup"></i>
                    </div>
                    <h3>
                        <a href="single-services.html">Skyrocketing Growth</a>
                    </h3>
                    <p>Nulla quis lorem ut libero malesuada feugiat sed porttitor lectus nibh. Proin eget tortor risus nulla quis lorem ut libero.</p>
                    <a href="single-services.html" class="services-btn">View More <i class="flaticon-next-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>