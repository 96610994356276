<div
    class="plor-services-area ptb-100"
    [ngClass]="{'d-none': router.url === '/home-eleven' || router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="section-title with-gradient-color">
            <span class="sub-title">SERVICES</span>
            <h2>Let’s Check Our Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>
        <div class="plor-services-slides owl-carousel owl-theme">
            <div class="plor-services-card wow animate__animated animate__animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                <div class="icon">
                    <i class="flaticon-megaphone"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Social Media Marketing</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                <div class="icon color-two">
                    <i class="flaticon-keywords"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Keyword Research</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                <div class="icon color-three">
                    <i class="flaticon-content-management"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Content Marketing</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                <div class="icon color-four">
                    <i class="flaticon-competitor"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Competitor Research</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                <div class="icon">
                    <i class="flaticon-megaphone"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Social Media Marketing</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                <div class="icon color-two">
                    <i class="flaticon-keywords"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Keyword Research</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                <div class="icon color-three">
                    <i class="flaticon-content-management"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Content Marketing</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                <div class="icon color-four">
                    <i class="flaticon-competitor"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Competitor Research</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                <div class="icon">
                    <i class="flaticon-megaphone"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Social Media Marketing</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                <div class="icon color-two">
                    <i class="flaticon-keywords"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Keyword Research</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                <div class="icon color-three">
                    <i class="flaticon-content-management"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Content Marketing</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
            <div class="plor-services-card wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                <div class="icon color-four">
                    <i class="flaticon-competitor"></i>
                </div>
                <h3>
                    <a routerLink="/single-services">Competitor Research</a>
                </h3>
                <p>Nulla porttitor accumsan tincidunt xed porttitor lectus nibh xed porttitor lectus nibh.</p>
                <a routerLink="/single-services" class="services-btn">View More <i class="flaticon-next-button"></i></a>
            </div>
        </div>
    </div>
    <div class="plor-services-shape">
        <img src="assets/img/more-home/services/vector-shape.png" alt="image">
    </div>
</div>

<!-- This section for Home Demo - 11 -->
<div
    class="it-services-area ptb-100 d-none"
    [ngClass]="{'d-block': router.url === '/home-eleven'}"
>
    <div class="container">
        <div class="startup-it-agency-title style-two">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="div">
                        <span>Our services</span>
                        <h2>What services we provide to our customers business</h2>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore dolore magna aliqua. quis adipisicing elit nostrud exercitation ullamco laboris.</p>
                </div>
            </div>
        </div>
        <div class="it-services-slides owl-carousel owl-theme">
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services1.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon1.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Cloud compuiting</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services2.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon2.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Corporate finance</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services3.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon3.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Manage investment</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services4.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon4.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Business planning</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services1.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon1.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Cloud compuiting</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services2.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon2.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Corporate finance</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services3.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon3.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Manage investment</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services4.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon4.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Business planning</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services1.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon1.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Cloud compuiting</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services2.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon2.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Corporate finance</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services3.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon3.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Manage investment</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
            <div class="it-services-card">
                <div class="services-image">
                    <a routerLink="/single-services"><img src="assets/img/startup-it-agency/services/services4.jpg" alt="image"></a>
                </div>
                <div class="services-content">
                    <div class="icon">
                        <img src="assets/img/startup-it-agency/services/icon4.svg" alt="image">
                    </div>
                    <h3>
                        <a routerLink="/single-services">Business planning</a>
                    </h3>
                    <p>Proin gravida nibh velit auctor aliquet enean sollicitudin bibendum auctor nisi elit.</p>
                    <a routerLink="/single-services" class="services-btn">Read more</a>
                </div>
            </div>
        </div>
    </div>
    <div class="it-services-shape">
        <img src="assets/img/startup-it-agency/services/shape.png" alt="image">
    </div>
</div>

<!-- This section for Home Demo - 12 -->
<div
    class="ds-services-area pt-100 pb-70 d-none"
    [ngClass]="{'d-block': router.url === '/home-twelve'}"
>
    <div class="container">
        <div class="ds-section-title">
            <span>Welcome to dibiz</span>
            <h2>Check our best services</h2>
            <p>Integer vel elit congue accumsan ligula placerat mi uisque tempor sapien justo sed accumsan tortor ultrices nec.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="ds-services-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/data-science/services/database.svg" alt="image">
                        </div>
                        <h3>
                            <a routerLink="/single-services">Data analysis</a>
                        </h3>
                        <p>Integer vel elit congue ligula placerat mi uisque tempor saen justo sed accumsan tortor.</p>
                        <a routerLink="/single-services" class="services-btn">Read more <i class='flaticon-chevron'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="ds-services-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/data-science/services/automated-engineering.svg" alt="image">
                        </div>
                        <h3>
                            <a routerLink="/single-services">Business intelligence</a>
                        </h3>
                        <p>Integer vel elit congue ligula placerat mi uisque tempor saen justo sed accumsan tortor.</p>
                        <a routerLink="/single-services" class="services-btn">Read more <i class='flaticon-chevron'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="ds-services-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/data-science/services/report.svg" alt="image">
                        </div>
                        <h3>
                            <a routerLink="/single-services">Machine learning</a>
                        </h3>
                        <p>Integer vel elit congue ligula placerat mi uisque tempor saen justo sed accumsan tortor.</p>
                        <a routerLink="/single-services" class="services-btn">Read more <i class='flaticon-chevron'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="ds-services-card">
                    <div class="content">
                        <div class="icon">
                            <img src="assets/img/data-science/services/code.svg" alt="image">
                        </div>
                        <h3>
                            <a routerLink="/single-services">Big data analysis</a>
                        </h3>
                        <p>Integer vel elit congue ligula placerat mi uisque tempor saen justo sed accumsan tortor.</p>
                        <a routerLink="/single-services" class="services-btn">Read more <i class='flaticon-chevron'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>