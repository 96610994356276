<div class="data-science-color-css">
    <app-hometwelve-banner></app-hometwelve-banner>

    <app-services></app-services>
    
    <app-about></app-about>

    <app-funfacts></app-funfacts>

    <app-features></app-features>

    <app-process></app-process>

    <app-hometwelve-tabs></app-hometwelve-tabs>

    <app-feedback></app-feedback>

    <app-team-style-six></app-team-style-six>
    
    <app-works></app-works>

    <app-pricing></app-pricing>
    
    <app-blog></app-blog>

    <app-subscribe></app-subscribe>
</div>