<div class="{{navbarClass}}">
    <div class="plor-responsive-nav">
        <div class="container-fluid">
            <div class="plor-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/entegi_logo.svg" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="plor-nav">
        <div class="{{containerClass}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/entegi_logo.svg" alt="logo">
                </a>
                
                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">{{'text_menu_solutions'|translate}} <i class='bx bx-chevron-down'></i></a>
                            
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">{{'text_menu_integrations' | translate}} <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><i class="flat flaticon-content-management"></i> <a routerLink="/team-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_marketplace_solutions' | translate}} </a></li>
                                        <li class="nav-item"><i class="flat flaticon-keywords"></i> <a routerLink="/team-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_cargo_integrations' | translate}} </a></li>
                                        <li class="nav-item"><i class="flat flaticon-startup"></i> <a routerLink="/team-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_xml_integrations' | translate}} </a></li>
                                        <li class="nav-item"><i class="flat flaticon-jigsaw"></i> <a routerLink="/team-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_webservice_integrations' | translate}} </a></li>
                                        <li class="nav-item"><i class="flat flaticon-diamond"></i> <a routerLink="/team-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_analytics_integrations' | translate}} </a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">{{'text_ecommerce' | translate}} <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><i class="flat flaticon-paper-plane"></i> <a routerLink="/team-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_catalog_management'|translate}}</a></li>
                                        <li class="nav-item"><i class="flat flaticon-speed"></i> <a routerLink="/team-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_stock_and_price_management'|translate}}</a></li>
                                        <li class="nav-item"><i class="flat flaticon-research"></i> <a routerLink="/team-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_order_management'|translate}}</a></li>
                                        <li class="nav-item"><i class="flat flaticon-conversation"></i> <a routerLink="/team-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_accounting_management'|translate}}</a></li>
                                        <li class="nav-item"><i class="flat flaticon-darts"></i> <a routerLink="/team-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_e_invoice_management'|translate}}</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item integration_menu"><a href="javascript:void(0)" class="nav-link">{{'text_menu_integrations'|translate}} <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <span>Pazaryeri</span>
                                    <a routerLink="/about-us-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/trendyol.svg" /> Trendyol</a>
                                    <a routerLink="/about-us-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/hepsiburada.svg" /> Hepsiburada</a>
                                    <a routerLink="/about-us-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/n11.svg" /> N11</a>
                                    <a routerLink="/about-us-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/ciceksepeti.svg" /> Çiçeksepeti</a>
                                    <a routerLink="/about-us-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/pttavm.svg" /> PttAvm</a>
                                    <a routerLink="/about-us-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/akakce.svg" /> Akakçe</a>
                                    <a routerLink="/about-us-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/amazon.svg" /> Amazon</a>
                                </li>
                                <li class="nav-item">
                                    <span>E-ticaret Altyapısı</span>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/rgs.svg" /> RGS</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/shopify.svg" /> Shopify</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/opencart.svg" /> Opencart</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/woocommerce.svg" /> Woocommerce</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/wix.svg" /> Wix</a>

                                    <br />
                                    <span class="submenu_title"> Ödeme</span>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/paytr.svg" /> PayTR</a>
                                    
                                </li>
                                <li class="nav-item">
                                    <span>Muhasebe</span>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/parasut.svg" /> Paraşüt</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/bizimhesap.svg" /> BizimHesap</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/dia.svg" /> Dia Muhasebe</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/uyumsoft.svg" /> Uyumsoft</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/edm.svg" /> EDM</a>
                                    <br />
                                    <span class="submenu_title"> Xml Entegrasyonları</span>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/xml_import.svg" /> Xml Entegrasyonu</a>
                                    
                                </li>
                                <li class="nav-item">
                                    <span>Kargo</span>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/araskargo.svg" /> Aras Kargo</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/yurticikargo.svg" /> Yurtiçi Kargo</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/mngkargo.svg" /> MNG Kargo</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/suratkargo.svg" /> Sürat Kargo</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/ptt_kargo.svg" /> PTT Kargo</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/ups.svg" /> UPS Kargo</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/hepsijet.svg" /> Hepsijet Kargo</a>
                                    <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img src="/assets/img/svg/sendeo.svg" /> Sendeo Kargo</a>
                                </li>
                            </ul>
                           
                        </li>
                        <li class="nav-item"><a routerLink="/pricing" class="nav-link">{{'text_menu_pricing'|translate}}</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">{{'text_menu_support'|translate}} <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>
                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/products-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products List</a></li>
                                        <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                                        <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                                        <li class="nav-item"><a routerLink="/single-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                                <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login/Register</a></li>
                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                                <li class="nav-item"><a routerLink="/terms-of-service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms of Service</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid (2 in Row)</a></li>
                                <li class="nav-item"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid (3 in Row)</a></li>
                                <li class="nav-item"><a routerLink="/blog-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid (Full Width)</a></li>
                                <li class="nav-item"><a routerLink="/blog-4" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar</a></li>
                                <li class="nav-item"><a routerLink="/blog-5" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Left Sidebar</a></li>
                                <li class="nav-item"><a routerLink="/single-blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'text_menu_contact'|translate}}</a></li>
                    </ul>
                    <div class="others-option d-flex align-items-center">
                        <!-- <div class="option-item">
                            <div class="search-box">
                                <i class="flaticon-search" (click)="searchToggleClass()"></i>
                            </div>
                        </div> -->
                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="flaticon-menu" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            <div class="container">
                <div class="option-inner">
                    <div class="others-option justify-content-center d-flex align-items-center">
                        <!-- <div class="option-item">
                            <div class="search-box">
                                <i class="flaticon-search"></i>
                            </div>
                        </div> -->
                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="flaticon-menu" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Sidebar Modal -->
<div class="sidebarModal modal right fade" id="sidebarModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal"><i class='bx bx-x'></i></button>
            <div class="modal-body">
                <div class="logo">
                    <a routerLink="/" class="d-inline-block"><img src="assets/img/entegi_logo.svg" alt="image"></a>
                </div>
                <div class="instagram-list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/team/team-img1.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/team/team-img2.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/team/team-img3.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/team/team-img4.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/team/team-img5.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/team/team-img6.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-contact-info">
                    <h2>
                        <a href="tel:+11234567890">+1 (123) 456 7890</a>
                        <span>OR</span>
                        <a href="mailto:hello&#64;plor.com">hello&#64;plor.com</a>
                    </h2>
                </div>
                <ul class="social-list">
                    <li><span>Follow Us On:</span></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-twitch'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->

<!-- Search Overlay -->
<div class="search-overlay" [class.search-overlay-active]="searchClassApplied">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="searchToggleClass()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-search"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Search Overlay -->