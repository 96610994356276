<app-hometen-main-banner></app-hometen-main-banner>

<app-services-style-three></app-services-style-three>

<app-hometen-about></app-hometen-about>

<app-what-we-do-style-four></app-what-we-do-style-four>

<app-case-studies-style-two></app-case-studies-style-two>

<app-feedback-style-two></app-feedback-style-two>

<app-solutions></app-solutions>

<div class="pb-100">
    <app-features-style-two></app-features-style-two>
</div>

<section class="pricing-area bg-f9f9f9 pt-100 pb-70">
    <app-pricing></app-pricing>
</section>

<app-team-style-four></app-team-style-four>

<section class="partner-area bg-f9f9f9 ptb-70">
    <app-partner></app-partner>
</section>

<app-hometen-blog></app-hometen-blog>

<app-subscribe-style-three></app-subscribe-style-three>