<div class="plor-what-we-do-area bg-F7F8FF">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="plor-what-we-do-content wow animate__animated animate__fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                    <span>WHAT WE DO</span>
                    <h3>We Help to Website Growth With Next Level Visitor</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse ultrices gravida.</p>
                    <div class="accordion" id="whatWeDoAccordion">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Social Media Optimization <i class="flaticon-minus"></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#whatWeDoAccordion">
                                <div class="accordion-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Content Generation <i class="flaticon-minus"></i>
                                </button>
                            </div>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#whatWeDoAccordion">
                                <div class="accordion-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Pay Per Click Services <i class="flaticon-minus"></i>
                                </button>
                            </div>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#whatWeDoAccordion">
                                <div class="accordion-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="plor-what-we-do-image wow animate__animated animate__fadeInRight" data-wow-delay="200ms" data-wow-duration="1000ms"></div>
            </div>
        </div>
    </div>
</div>