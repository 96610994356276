<div class="new-main-banner-area banner-with-gradient-color">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-main-banner-content">
                    <span class="sub-title wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">SEO MARKETING</span>
                    <h1 class="wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">Boots Your Website Traffic</h1>
                    <p class="wow animate__animated animate__fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse ultrices gravida.</p>
                    <div class="btn-box wow animate__animated animate__fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
                        <a routerLink="/contact" class="default-btn-with-radius">Get Started <i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-main-banner-image wow animate__animated animate__fadeInRight" data-wow-delay="300ms" data-wow-duration="1000ms">
                    <img src="assets/img/more-home/banner/banner-2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>