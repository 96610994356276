<div class="plor-blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title with-gradient-color">
            <span class="sub-title">OUR BLOG</span>
            <h2>Our Latest Media</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="plor-blog-card wow animate__animated animate__fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                    <div class="blog-image">
                        <a routerLink="/single-blog-1"><img src="assets/img/more-home/blog/blog-1.png" alt="image"></a>
                    </div>
                    <div class="blog-content">
                        <h3>
                            <a routerLink="/single-blog-1">Digital Marketing Agency Blogs You Should Read</a>
                        </h3>
                        <div class="blog-info d-flex align-items-center">
                            <div class="info d-flex align-items-center">
                                <img src="assets/img/more-home/blog/image-1.png" alt="image">
                                <h5>Claris Collins</h5>
                            </div>
                            <div class="info-date">
                                <span><i class='bx bxs-calendar'></i> January 20, 2022</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="plor-blog-card wow animate__animated animate__fadeInUp" data-wow-delay="400ms" data-wow-duration="1000ms">
                    <div class="blog-image">
                        <a routerLink="/single-blog-1"><img src="assets/img/more-home/blog/blog-2.png" alt="image"></a>
                    </div>
                    <div class="blog-content">
                        <h3>
                            <a routerLink="/single-blog-1">Digital Marketing Strategies For Lead Generation</a>
                        </h3>
                        <div class="blog-info d-flex align-items-center">
                            <div class="info d-flex align-items-center">
                                <img src="assets/img/more-home/blog/image-2.png" alt="image">
                                <h5>Adam Moody</h5>
                            </div>
                            <div class="info-date">
                                <span><i class='bx bxs-calendar'></i> January 20, 2022</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="plor-blog-card wow animate__animated animate__fadeInUp" data-wow-delay="500ms" data-wow-duration="1000ms">
                    <div class="blog-image">
                        <a routerLink="/single-blog-1"><img src="assets/img/more-home/blog/blog-3.png" alt="image"></a>
                    </div>
                    <div class="blog-content">
                        <h3>
                            <a routerLink="/single-blog-1">Agencies Can Successfully Recover From COVID?</a>
                        </h3>
                        <div class="blog-info d-flex align-items-center">
                            <div class="info d-flex align-items-center">
                                <img src="assets/img/more-home/blog/image-3.png" alt="image">
                                <h5>Melissa Todd</h5>
                            </div>
                            <div class="info-date">
                                <span><i class='bx bxs-calendar'></i> January 20, 2022</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>