<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="what-we-do-content">
                <span class="sub-title"></span>
                <h2>{{'text_how_works' | translate}}</h2>
                <p>{{'text_how_works_detail' | translate}}</p>
                <div class="skills-item">
                    <div class="skills-header">
                        <h4 class="skills-title">{{'text_marketplace_integrations' | translate}}</h4>
                        <div class="skills-percentage">
                            <div class="count-box">99%</div>
                        </div>
                    </div>
                    <div class="skills-bar">
                        <div class="bar-inner"><div class="bar progress-line" data-width="95"></div></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="what-we-do-content-accordion">
                <div class="accordion" id="whatWeDoAccordion">
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {{'text_how_works_box1' | translate}} <i class="flaticon-minus"></i>
                            </button>
                        </div>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#whatWeDoAccordion">
                            <div class="accordion-body">
                                <p>{{'text_how_work_box1_desc' | translate}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {{'text_how_works_box2' | translate}} <i class="flaticon-minus"></i>
                            </button>
                        </div>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#whatWeDoAccordion">
                            <div class="accordion-body">
                                <p>{{'text_how_work_box2_desc' | translate}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                {{'text_how_works_box3' | translate}} <i class="flaticon-minus"></i>
                            </button>
                        </div>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#whatWeDoAccordion">
                            <div class="accordion-body">
                                <p>{{'text_how_work_box3_desc' | translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>