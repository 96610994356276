<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>About Us</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="content">
                        <span class="sub-title">About Us</span>
                        <h2>Enjoy Full-Service Digital Marketing Expertise</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <ul class="funfacts-list">
                            <li>
                                <div class="list">
                                    <i class="flaticon-menu-1"></i>
                                    <h3 class="odometer" data-count="376">00</h3>
                                    <p>Completed projects</p>
                                </div>
                            </li>
                            <li>
                                <div class="list">
                                    <i class="flaticon-web-settings"></i>
                                    <h3 class="odometer" data-count="7548">00</h3>
                                    <p>Working hours were spent</p>
                                </div>
                            </li>
                            <li>
                                <div class="list">
                                    <i class="flaticon-conversation"></i>
                                    <h3 class="odometer" data-count="350">00</h3>
                                    <p>Expert team members</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-history></app-history>

<section class="team-area ptb-100">
    <app-team-style-one></app-team-style-one>
</section>

<section class="partner-area bg-f9f9f9 ptb-70">
    <app-partner></app-partner>
</section>

<app-testimonials></app-testimonials>